import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../catalog.css"
import ReactPlayer from "react-player"

const CatalogPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <main role="main">
        <div class="mv">
          <div class="mv-container">
            <p class="mv-title">カタログの紹介</p>
            <p class="mv-subtitle">Catalog</p>
            <p class="mv-text">
              このページはjamstackホームページ制作屋の
              <br class="sp-only" />
              カタログページです。
              <br />
              デザインとコーディングは
              <br class="sp-only" />
              おまかせください。
              <br />
            </p>
          </div>
          <ReactPlayer
            className="react-player"
            url="/video-background-demo.mp4"
            width="100%"
            height="100%"
            playing
            muted
            loop
            playsinline
          />
        </div>

        <section class="works section" id="works">
          <div class="container">
            <h2 class="title">カタログ</h2>
            <div class="works-list">
              <Link
                class="works-item"
                href="https://catalog-white-001.jamstack-media.com/"
              >
                <div class="works-img">
                  <StaticImage
                    src="../images/カタログ白001.png"
                    alt="works-sample-thumb"
                    width={400}
                    height={300}
                  />
                </div>
                <p class="works-name">カタログ白001</p>
                <p class="works-info">Webサイトカタログ</p>
              </Link>
              <Link
                class="works-item"
                href="https://catalog-white-002.jamstack-media.com/"
              >
                <div class="works-img">
                  <StaticImage
                    src="../images/カタログ白002.png"
                    alt="works-dummy-thumb"
                    width={400}
                    height={300}
                  />
                </div>
                <p class="works-name">カタログ白002</p>
                <p class="works-info">Webサイトカタログ</p>
              </Link>
              <Link
                class="works-item"
                href="https://catalog-corporatesite-001.jamstack-media.com/"
              >
                <div class="works-img">
                  <StaticImage
                    src="../images/カタログコーポレート001-2.png"
                    alt="works-dummy-thumb"
                    width={400}
                    height={300}
                  />
                </div>
                <p class="works-name">カタログコーポレート001</p>
                <p class="works-info">Webサイトカタログ</p>
              </Link>
              <Link
                class="works-item"
                href="https://catalog-blog-001.jamstack-media.com/"
              >
                <div class="works-img">
                  <StaticImage
                    src="../images/カタログブログ001.png"
                    alt="works-dummy-thumb"
                    width={400}
                    height={300}
                  />
                </div>
                <p class="works-name">カタログ-ブログ-001</p>
                <p class="works-info">Webサイトカタログ</p>
              </Link>
              <Link
                class="works-item"
                href="https://catalog-blog-002.jamstack-media.com/"
              >
                <div class="works-img">
                  <StaticImage
                    src="../images/カタログブログ002.png"
                    alt="works-dummy-thumb"
                    width={400}
                    height={300}
                  />
                </div>
                <p class="works-name">カタログ-ブログ-002</p>
                <p class="works-info">Webサイトカタログ</p>
              </Link>
              <Link
                class="works-item"
                href="https://bakerysite.jamstack-media.com/"
              >
                <div class="works-img">
                  <StaticImage
                    src="../images/bakery2.png"
                    alt="works-dummy-thumb"
                    width={400}
                    height={300}
                  />
                </div>
                <p class="works-name">カタログ-パン屋-001</p>
                <p class="works-info">Webサイトカタログ</p>
              </Link>
              <Link
                class="works-item"
                href="https://catalog-clinic-001.jamstack-media.com/"
              >
                <div class="works-img">
                  <StaticImage
                    src="../images/clinic005.png"
                    alt="works-dummy-thumb"
                    width={400}
                    height={300}
                  />
                </div>
                <p class="works-name">カタログ-クリニック-001</p>
                <p class="works-info">Webサイトカタログ</p>
              </Link>
            </div>
          </div>
          <div class="container">
            <h2 class="title">実績</h2>
            <div class="works-list">
              <Link class="works-item" href="https://niku-nagaoka.com/">
                <div class="works-img">
                  <StaticImage
                    formats={["auto", "webp"]}
                    src="../images/肉のながおか.png"
                    quality={95}
                    alt="works-sample-thumb"
                  />
                </div>
                <p class="works-name">肉のながおか様</p>
                <p class="works-info">コーディング、デザイン、サーバー管理</p>
              </Link>
            </div>
          </div>
        </section>
        <section class="skill section" id="skill">
          <div class="container">
            <h2 class="title">スキルとサービス</h2>
            <div class="skill-list">
              <div class="skill-item">
                <p class="skill-img">
                  <StaticImage
                    width={150}
                    height={150}
                    src="../images/HTML5_Logo_256.png"
                    alt="icon_skill"
                  />
                </p>
                <div class="skill-body">
                  <h3 class="skill-name">HTML5/CSS3</h3>
                  <p class="skill-text">
                    私たちのチームは、HTML5とCSS3の最新の標準を利用してウェブサイトを設計し、開発しています。これにより、私たちが制作するウェブサイトはモダンで魅力的なデザイン、高いパフォーマンス、そして優れたユーザーエクスペリエンスを実現します。
                  </p>
                </div>
              </div>
              <div class="skill-item">
                <p class="skill-img">
                  <StaticImage
                    width={150}
                    src="../images/React-icon.png"
                    alt="icon_skill"
                  />
                </p>
                <div class="skill-body">
                  <h3 class="skill-name">JavaScript/React</h3>
                  <p class="skill-text">
                    私たちのチームは、JavaScriptとReactの専門家であり、これらの技術を駆使して最高品質のウェブサイトとウェブアプリケーションを制作します。この最先端のテクノロジーを用いることで、私たちは迅速で効率的に動作する、美しいウェブサイトを提供できます。これらのスキルと経験を活用し、クライアントのビジネスがデジタル空間で目立つよう助けることができます。私たちと一緒に、最新のJavaScriptとReact技術を活用し、ビジネスを新たな高みへと導きましょう。
                  </p>
                </div>
              </div>
              <div class="skill-item">
                <p class="skill-img">
                  <StaticImage src="../images/sitemap.png" alt="icon_skill" />
                </p>
                <div class="skill-body">
                  <h3 class="skill-name">
                    サイトマップの自動生成/メタタグを指定
                  </h3>
                  <p class="skill-text">
                    私たちが手掛けるウェブサイトは、サイトマップの自動生成機能を備えています。これにより、ウェブサイトの全ページが検索エンジンに正確にインデックスされ、最新の情報が常に検索エンジンに反映されます。
                    また、我々は各ページで適切なメタタグを指定します。これにより、検索エンジンはページの内容を適切に理解し、ユーザーの検索クエリに対して最適な結果を表示します。メタタグはまた、検索結果ページでのウェブサイトの見た目と表現を制御するため、クリック率を向上させます。
                  </p>
                </div>
              </div>
              <div class="skill-item">
                <p class="skill-img">
                  <StaticImage
                    width={150}
                    src="../images/aws-logo.png"
                    alt="icon_skill"
                  />
                </p>
                <div class="skill-body">
                  <h3 class="skill-name">AWS</h3>
                  <p class="skill-text">
                    私たちのチームは、Amazon Web Services (AWS)
                    の深い知識を持っています。これは世界をリードするクラウドサービスプロバイダーで、その幅広いサービスと機能により、我々は効率的で堅牢、スケーラブルなウェブサービスを展開することができます。
                  </p>
                </div>
              </div>
              <div class="skill-item">
                <p class="skill-img">
                  <StaticImage
                    width={150}
                    src="../images/nanocms.png"
                    alt="icon_skill"
                  />
                </p>
                <div class="skill-body">
                  <h3 class="skill-name">NanoCMS</h3>
                  <p class="skill-text">
                    「NanoCMS」は我々が開発したオリジナルのAPI
                    CMS（コンテンツ管理システム）です。静的サイトジェネレータと組み合わせることで、ユーザーはウェブサイトのコンテンツを簡単に管理し、更新することができます。
                  </p>
                </div>
              </div>
              <div class="skill-item">
                <p class="skill-img">
                  <StaticImage
                    src="../images/icon_security.png"
                    alt="icon_skill"
                  />
                </p>
                <div class="skill-body">
                  <h3 class="skill-name">セキュリティ</h3>
                  <p class="skill-text">
                    私たちは、提供する全てのサービスにおいてセキュリティを最優先に考えています。これはデジタル環境で事業を行う上で不可欠な要素であり、私たちの専門性と技術力の証でもあります。
                    静的サイトジェネレーターを使用することにより、ウェブサイトはプレビルドのHTMLファイルとして提供されます。これにより、リアルタイムのデータベースへのクエリやサーバーサイドのスクリプトの実行が不要になり、サイトが潜在的な脆弱性から守られます。さらに、静的サイトはDDoS攻撃のリスクを大幅に減らします。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="about section" id="about">
          <div class="container">
            <h2 class="title">私たちについて</h2>
            <div class="profile">
              <p class="profile-img">
                <StaticImage
                  width={300}
                  height={300}
                  src="../images/自然4.png"
                  alt="profile"
                />
              </p>
              <div class="profile-body">
                <p>
                  私たちの事業は、JAMstackを利用したホームページ制作に注力しています。このモダンで革新的なアプローチを選んだ理由の一つが、サステイナビリティ、つまり持続可能性にあります。
                </p>
                <p>
                  我々の開発手法は、環境負荷の軽減、経済的なコストの削減、そして長期的な運用とメンテナンスの持続可能性を重視しています。静的サイトジェネレーターはサーバーのリソースを最小限に抑え、必要なエネルギーを減らします。これにより、地球への負荷を軽減し、同時にコスト効率の高い運用が可能になります。
                </p>
                <p>
                  また、JAMstackのシンプルなアーキテクチャは長期的な管理と更新を容易にします。テクノロジーの進歩に合わせて、我々のサイトは素早く適応し、新しい要件を統合することが可能です。これは事業の成長とともに新たな要素が追加されることを考慮すると、経済的な持続可能性に貢献します。
                </p>
                <p>
                  私たちが提供するJAMstackのホームページ制作は、クライアントがビジネスを持続的に成長させ、同時に地球環境に配慮するための選択肢と言えます。私たちと一緒に、より良いデジタルの未来を創造しましょう。
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default CatalogPage

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="カタログ"
    description="Jamstackホームページ制作屋のカタログです。デザインとコーディングはおまかせください。"
    cover={"/work.png"}
  />
)

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
